.SidebarCollapseArrow {
    display: inline-block;
    width: 12px;
    margin-left: 5px;
}

.SidebarCollapseArrow img {
    max-width: 100%;
    height: auto;
}

.ScreenContainer-sidebar-link-collapsable.open .SidebarCollapseArrow {
    transform: rotate(180deg);
}