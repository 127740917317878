@font-face {
  font-family: "Int Circular 2 TT";
  src: url("https://storage.googleapis.com/plp-kiosk/fonts/IntCircular2TT/IntCircular2TT-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Int Circular 2 TT";
  src: url("https://storage.googleapis.com/plp-kiosk/fonts/IntCircular2TT/IntCircular2TT-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Int Circular 2 TT";
  src: url("https://storage.googleapis.com/plp-kiosk/fonts/IntCircular2TT/IntCircular2TT-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Int Circular 2 TT Book";
  src: url("https://storage.googleapis.com/plp-kiosk/fonts/IntCircular2TT/IntCircular2TT-Book.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Int Circular 2 TT Book";
  src: url("https://storage.googleapis.com/plp-kiosk/fonts/IntCircular2TT/IntCircular2TT-BookItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Int Circular 2 TT";
  src: url("https://storage.googleapis.com/plp-kiosk/fonts/IntCircular2TT/IntCircular2TT-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Int Circular 2 TT";
  src: url("https://storage.googleapis.com/plp-kiosk/fonts/IntCircular2TT/IntCircular2TT-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Int Circular 2 TT";
  src: url("https://storage.googleapis.com/plp-kiosk/fonts/IntCircular2TT/IntCircular2TT-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Int Circular 2 TT', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Breakpoints */

.small,
.medium,
.large,
.x-large,
.xx-large {
  display: none !important;
}

@media screen and (min-width: 576px) {
  .small {
    display: initial !important;
  }
}

@media screen and (min-width: 768px) {
  .medium {
    display: initial !important;
  }
}

@media screen and (min-width: 992px) {
  .large {
    display: initial !important;
  }
}

@media screen and (min-width: 1200px) {
  .x-large {
    display: initial !important;
  }
}

@media screen and (min-width: 1400px) {
  .xx-large {
    display: initial !important;
  }
}

/* Text */

.red-text {
  color: #E06849;
}

/* Buttons */

.primary-button,
.secondary-button,
.dark-button,
.green-button,
.blue-button,
.white-button,
a.primary-button,
a.secondary-button,
a.dark-button,
a.green-button,
a.blue-button,
a.white-button {
  appearance: none;
  -webkit-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: unset;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  text-decoration: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.primary-button {
  border-style: solid;
  border-width: 2px;
  border-color: #4AC1E0;
  background-color: #4AC1E0;
  color: #FFFFFF;
}

.primary-button:active {
  background-color: transparent;
  color: #4AC1E0;
}

.dark-button {
  border-style: solid;
  border-width: 2px;
  border-color: #1F1E1D;
  background-color: #1F1E1D;
  color: #FFFFFF;
}

.dark-button:active {
  background-color: transparent;
  color: #1F1E1D;
}

.green-button {
  border-style: solid;
  border-width: 2px;
  border-color: #5EBE7A;
  background-color: #5EBE7A;
  color: #fff;
}

.green-button:active {
  background-color: transparent;
  color: #5EBE7A;
}

.blue-button {
  border-style: solid;
  border-width: 2px;
  border-color: #4975A6;
  background-color: #4975A6;
  color: #fff;
}

.blue-button:active {
  background-color: transparent;
  color: #4975A6;
}

.white-button {
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  background-color: #fff;
  color: #000;
}

.white-button:active {
  background-color: transparent;
  border-color: #000;
  color: #000;
}

.large-button {
  height: 50px;
  font-size: 18px;
}

.full-button {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

/* LINKS */

.primary-link {
  text-decoration: none;
  font-weight: bold;
  color: #4AC1E0;
  cursor: pointer;
}

.white-link {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  border: none;
  background: transparent;
}