.DateField {
    position: relative;
}

.DateField .DateField-container {
    position: relative;
    z-index: 1;
    background-color: #fff;
}

.DateField .DateField-container .DateField-value-container {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #000;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.FormField.error .DateField .DateField-container .DateField-value-container {
    border-bottom-color: #E06849;
}

.DateField .DateField-container .DateField-value-container .DateField-placeholder {
    color: #8f8f8f;
}

.DateField .DateField-container .DateField-value-container .DateField-clear-btn {
    display: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.DateField .DateField-container .DateField-value-container:hover .DateField-clear-btn {
    display: block;
}

.DateField .DateField-container .DateField-calendar {
    display: none;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.DateField .DateField-container .DateField-calendar .CalendarSelection .CalendarSelection-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.DateField.open .DateField-container .DateField-calendar {
    display: block;
}