/* .RadioOption {} */

.RadioOption .RadioOption-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.RadioOption .RadioOption-container .RadioOption-circle {
    height: 20px;
    width: 20px;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    margin-right: 7px;
    background-color: transparent;
}

.RadioOption.selected .RadioOption-container .RadioOption-circle {
    background-color: #4AC1E0;
}

.RadioOption .RadioOption-container .RadioOption-label {}