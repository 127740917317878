.ScreenContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.ScreenContainer .ScreenContainer-container {
    flex: 1;
    position: relative;
}

.ScreenContainer .ScreenContainer-container .ScreenContainer-topbar .ScreenContainer-topbar-container {
    background-color: #000;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ScreenContainer .ScreenContainer-container .ScreenContainer-topbar .ScreenContainer-topbar-container .ScreenContainer-topbar-sidebar-toggle {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.ScreenContainer .ScreenContainer-container .ScreenContainer-topbar .ScreenContainer-topbar-container .ScreenContainer-topbar-sidebar-toggle img {
    max-width: 100%;
}

.ScreenContainer .ScreenContainer-container .ScreenContainer-topbar .ScreenContainer-topbar-container .ScreenContainer-logout-button {
    margin-left: auto;
}

.ScreenContainer .ScreenContainer-container .ScreenContainer-sidebar {
    display: none;
    position: absolute;
    z-index: 5;
    top: 65px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.ScreenContainer .ScreenContainer-container .ScreenContainer-sidebar.open {
    display: block;
    overflow-y: hidden;
}

.ScreenContainer .ScreenContainer-container .ScreenContainer-sidebar .ScreenContainer-sidebar-container {
    background-color: #000;
    color: #fff;
    padding: 20px;
    height: 100%;
    max-width: 100%;
}

@media screen and (min-width: 768px) {
    .ScreenContainer .ScreenContainer-container .ScreenContainer-sidebar .ScreenContainer-sidebar-container {
        width: 300px;
    }
}

.ScreenContainer-sidebar .ScreenContainer-sidebar-container .ScreenContainer-sidebar-link {
    margin-bottom: 20px;
}

.ScreenContainer-sidebar .ScreenContainer-sidebar-container .ScreenContainer-sidebar-link .ScreenContainer-sidebar-link-text {
    cursor: pointer;
}

.ScreenContainer-sidebar .ScreenContainer-sidebar-container .ScreenContainer-sidebar-link.active > .ScreenContainer-sidebar-link-text {
    color: #4AC1E0;
}

.ScreenContainer-sidebar .ScreenContainer-sidebar-container .ScreenContainer-sidebar-link .ScreenContainer-sidebar-link-text a {
    text-decoration: none;
    text-transform: none;
    color: inherit;
}

.ScreenContainer-sidebar .ScreenContainer-sidebar-container .ScreenContainer-sidebar-link-collapsable .ScreenContainer-sidebar-sublinks {
    display: none;
    margin-top: 10px;
    margin-left: 10px;
}

.ScreenContainer-sidebar .ScreenContainer-sidebar-container .ScreenContainer-sidebar-link-collapsable.open .ScreenContainer-sidebar-sublinks {
    display: block;
}

.ScreenContainer-sidebar .ScreenContainer-sidebar-container .ScreenContainer-sidebar-link-collapsable .ScreenContainer-sidebar-sublinks .ScreenContainer-sidebar-sublink {
    margin-bottom: 5px;
}

.ScreenContainer-sidebar .ScreenContainer-sidebar-container .ScreenContainer-sidebar-link-collapsable .ScreenContainer-sidebar-sublinks .ScreenContainer-sidebar-sublink .ScreenContainer-sidebar-sublink-text {
    cursor: pointer;
}

.ScreenContainer-sidebar .ScreenContainer-sidebar-container .ScreenContainer-sidebar-link-collapsable .ScreenContainer-sidebar-sublinks .ScreenContainer-sidebar-sublink.active .ScreenContainer-sidebar-sublink-text {
    color: #4AC1E0;
}

.ScreenContainer-sidebar-new-lead-button {
    margin-top: 40px;
}

.ScreenContainer .ScreenContainer-container .ScreenContainer-content {
    padding: 20px;
}