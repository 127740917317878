.ProfileAppointmentSelector .ProfileAppointmentSelector-footer .ProfileAppointmentSelector-footer-container {
    position: relative;
    padding: 15px;
    border-style: solid;
    border-width: 2px;
    border-color: #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: #fff;
    text-align: center;
}

.ProfileAppointmentSelector .ProfileAppointmentSelector-tab {
    margin-top: 20px;
}

.ProfileAppointmentSelector .ProfileAppointmentSelector-tab.hide {
    display: none;
}

.ProfileAppointmentSelector .ProfileAppointmentSelector-tab.show {
    display: block;
}

.ProfileAppointmentSelector .ProfileAppointmentSelector-update-btn {
    margin-top: 10px;
}

.ProfileAppointmentSelector .ProfileAppointmentSelector-cancel-btn {
    position: absolute;
    right: 20px;
    display: none;
    cursor: pointer;
    font-size: 18px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ProfileAppointmentSelector .ProfileAppointmentSelector-cancel-btn:active {
    opacity: 0.5;
}

.ProfileAppointmentSelector .ProfileAppointmentSelector-footer:hover .ProfileAppointmentSelector-cancel-btn {
    display: inline;
}