.SingleLineTextField .SingleLineTextField-container input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-progress-appearance: none;
    border: none;
    width: 100%;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    font-family: 'Int Circular 2 TT', sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #000;
}

.FormField.error .SingleLineTextField .SingleLineTextField-container input {
    border-bottom-color: #E06849;
}

.SingleLineTextField .SingleLineTextField-container input::placeholder {
    font-family: 'Int Circular 2 TT', sans-serif;
    font-size: 16px;
    color: #8f8f8f;
}

.SingleLineTextField .SingleLineTextField-container input:focus {
    outline: none;
}

.SingleLineTextField .SingleLineTextField-container .SingleLineTextField-default {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #525252;
    overflow-x: hidden;
}

.SingleLineTextField .SingleLineTextField-container .SingleLineTextField-default .SingleLineTextField-default-value {
    color: #525252;
    font-size: 16px;
}

.SingleLineTextField .SingleLineTextField-container .SingleLineTextField-default .SingleLineTextField-change-button {
    font-size: 12px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #000;
    font-weight: bold;
}