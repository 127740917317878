.ObjectQueue-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 15px;
}

.ObjectQueue-message {
    border-style: solid;
    border-width: 2px;
    border-color: #1F1E1D;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
}