.TableRow .TableRow-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 20px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #000;
    cursor: pointer;
}