.ProfileAdjustableDateHeader {
    margin-bottom: 30px;
}

.ProfileAdjustableDateHeader .ProfileAdjustableDateHeader-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.ProfileAdjustableDateHeader .ProfileAdjustableDateHeader-container .ProfileAdjustableDateHeader-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000;
    color: #fff;
    padding: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.ProfileAdjustableDateHeader .ProfileAdjustableDateHeader-container .ProfileAdjustableDateHeader-title .ProfileAdjustableDateHeader-title-text {
    font-size: 32px;
    font-weight: bold;
}

.ProfileAdjustableDateHeader .ProfileAdjustableDateHeader-container .ProfileAdjustableDateHeader-footer .ProfileAdjustableDateHeader-footer-container {
    padding: 15px;
    border-style: solid;
    border-width: 2px;
    border-color: #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: #fff;
    cursor: pointer;
}

.ProfileAdjustableDateHeader .ProfileAdjustableDateHeader-container .ProfileAdjustableDateHeader-footer:hover .ProfileAdjustableDateHeader-footer-container {
    background-color: #efefef;
}

.ProfileAdjustableDateHeader .ProfileAdjustableDateHeader-container .ProfileAdjustableDateHeader-footer:active .ProfileAdjustableDateHeader-footer-container {
    background-color: #dfdfdf;
}

.ProfileAdjustableDateHeader .ProfileAdjustableDateHeader-container .CalendarSelection {
    margin-top: 20px;
}