.ProfileForm {
    width: 100%;
}

.ProfileForm .ProfileForm-container {
    width: 100%;
}

.ProfileForm .ProfileForm-container .submit-button {
    margin-top: 20px;
}