.RequestScreen {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.RequestScreen .RequestScreen-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
}

.RequestScreen .RequestScreen-container .RequestScreen-header .RequestScreen-header-container {
    background-color: #000;
    color: #fff;
    position: relative;
    display: block;
    padding-top: 15px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-left: 20px;
    font-family: 'Int Circular 2 TT Book', 'Int Circular 2 TT', sans-serif;
}

.RequestScreen .RequestScreen-container .RequestScreen-header .RequestScreen-header-container .RequestScreen-header-go-back {
    position: absolute;
    top: 0px;
    left: 20px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.RequestScreen .RequestScreen-container .RequestScreen-header .RequestScreen-header-container .RequestScreen-header-go-back button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-progress-appearance: none;
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    font-size: 22px;
}

.RequestScreen .RequestScreen-container .RequestScreen-header .RequestScreen-header-container .RequestScreen-header-title {
    text-align: center;
}

.RequestScreen .RequestScreen-container .RequestScreen-tab-list {
    flex: 1;
    display: flex;
    justify-content: stretch;
}

.RequestScreen .RequestScreen-container .RequestScreen-tab-list .RequestScreen-tab-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    margin: auto;
    padding: 30px;
}

.RequestScreen .RequestScreen-container .RequestScreen-tab-list .RequestScreen-tab-list-container .RequestScreen-tab {
    display: none;
}

.RequestScreen .RequestScreen-container .RequestScreen-tab-list .RequestScreen-tab-list-container .RequestScreen-tab.active {
    display: block;
}

.RequestScreen .RequestScreen-container .RequestScreen-tab-list .RequestScreen-tab-list-container .RequestScreen-tab .RequestScreen-tab-container .RequestScreen-cancel-button {
    margin-bottom: 30px;
}