.FileThumbnail .FileThumbnail-container {
    height: 25px;
    width: 35px;
    padding-top: 10px;
    padding-right: 5px;
    padding-bottom: 10px;
    padding-left: 5px;
    background-color: #4975A6;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    overflow-x: hidden;
    position: relative;
}

.FileThumbnail .FileThumbnail-container .FileThumbnail-type {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.FileThumbnail .FileThumbnail-container .FileThumbnail-loader {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #fff;
    opacity: 0.75;
    z-index: 0;
}

.FileThumbnail.loading .FileThumbnail-container .FileThumbnail-loader {
    display: block;
}