.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.App .App-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.App.loading .App-container {
    display: none;
}

.App .App-loader {
    display: none;
    flex: 1;
    transition: 2s;
}

.App.loading .App-loader {
    display: block;
}

.App .App-loader .App-loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    height: 100%;
}

.App .App-loader .App-loader-container .App-loader-spinner {
    margin: auto;
}