.ScheduleConsultationScreen-form, .ScheduleConsultationScreen-header, .ScheduleConsultationScreen-description, .ScheduleConsultationScreen-lead-data {
    max-width: 750px;
    margin: auto;
}

.ScheduleConsultationScreen-upper-content .CollapsibleNotes {
    max-width: 750px;
    margin: auto;
}

.ScheduleConsultationScreen-header {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 20px;
}

.ScheduleConsultationScreen-header .ScheduleConsultationScreen-header-container {
    display: table-row;
}

.ScheduleConsultationScreen-header .ScheduleConsultationScreen-header-container .ScheduleConsultationScreen-header-lead-name, .ScheduleConsultationScreen-header .ScheduleConsultationScreen-header-container .ScheduleConsultationScreen-header-status-btn-container {
    display: table-cell;
}

.ScheduleConsultationScreen-header .ScheduleConsultationScreen-header-lead-name {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
}

.ScheduleConsultationScreen-header-status-btn {
    display: inline;
    padding-top: 7.5px;
    padding-right: 20px;
    padding-bottom: 7.5px;
    padding-left: 20px;
    background-color: #4AC1E0;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ScheduleConsultationScreen-header-status-btn:hover {
    background-color: #3a97ae;
}

.ScheduleConsultationScreen-header .ScheduleConsultationScreen-header-container .ScheduleConsultationScreen-header-status-btn-container {
    text-align: right;
}

.ScheduleConsultationScreen-lead-data {
    width: 100%;
}

.ScheduleConsultationScreen-lead-data-row {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 20px;
}

.ScheduleConsultationScreen-lead-data-row .ScheduleConsultationScreen-lead-data-row-container {
    display: table-row;
}

.ScheduleConsultationScreen-lead-data-row .ScheduleConsultationScreen-lead-data-field {
    display: table-cell;
    width: 50%;
}

.ScheduleConsultationScreen-lead-data-field-value {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ScheduledConsultationScreen-lead-data-field-label {
    font-size: 14px;
    text-transform: uppercase;
    color: #BABABA;
    margin-bottom: 7.5px;
}

.ScheduleConsultationScreen-description {
    margin-bottom: 10px;
    font-size: 14px;
    font-style: italic;
    color: #888888;
}

.ScheduleConsultationScreen-upper-content .CollapsibleNotes {
    margin-bottom: 30px;
}

.ScheduleConsultationScreen-form {
    background-color: #eaeaea;
    border-width: 2px;
    border-style: solid;
    border-color: #676767;
    padding: 20px;
}

.ChangeStatusModal {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.4);
    z-index: 2;
}

.ChangeStatusModal.hide {
    display: none;
}

.ChangeStatusModal-container {
    display: table;
    width: 100%;
    height: 100%;
}

.ChangeStatusModal-content {
    display: table-row;
}

.ChangeStatusModal-content-wrapper {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    padding: 20px;
}

.ChangeStatusModal-content-container {
    max-width: 750px;
    padding: 30px;
    background-color: #fff;
    margin: auto;
    box-shadow: 3px 3px 6px rgba(0,0,0,0.2);
}

.ChangeStatusModal-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.ChangeStatusModal-description {
    margin-bottom: 20px;
    font-size: 14px;
    font-style: italic;
    color: #888888;
}