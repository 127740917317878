.TimeField {
    display: flex;
    flex-direction: row;
}

.TimeField .TimeField-hour, .TimeField .TimeField-minute {
    margin-right: 10px;
    min-width: 75px;
}

.TimeField .TimeField-hour input, .TimeField .TimeField-minute input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-progress-appearance: none;
    width: 100%;
    box-sizing: border-box;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #000;
    font-size: 16px;
    font-family: 'Int Circular 2 TT', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.FormField.error .TimeField .TimeField-hour input, .FormField.error .TimeField .TimeField-minute input {
    border-bottom-color: #E06849;
}

.TimeField .TimeField-hour input:focus, .TimeField .TimeField-minute input:focus {
    outline: none;
}

.TimeField .TimeField-period {
    position: relative;
    min-width: 75px;
}

.TimeField .TimeField-period .TimeField-period-value {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #000;
    cursor: pointer;
    z-index: 1;
}

.FormField.error .TimeField .TimeField-period .TimeField-period-value {
    border-bottom-color: #E06849;
}

.TimeField .TimeField-period.open .TimeField-period-value {
    cursor: default;
}

.TimeField .TimeField-period .TimeField-period-options {
    display: none;
    position: absolute;
    z-index: 2;
    left: 0px;
    right: 0px;
    border-style: solid;
    border-width: 2px;
    border-color: #000;
    border-top-width: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.TimeField .TimeField-period.open .TimeField-period-options {
    display: block;
    background-color: #fff;
}

.TimeField .TimeField-period .TimeField-period-options .TimeField-period-option {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    cursor: pointer;
}