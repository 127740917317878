.ProfileModule {
    margin-bottom: 30px;
}

.ProfileModule .ProfileModule-container {
    background-color: #000;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    overflow-x: clip;
}