.SingleFileIcon {
    position: relative;
    width: 45px;
}

.SingleFileIcon .CircularCloseButton {
    display: none;
    z-index: 3;
}

.SingleFileIcon:hover .CircularCloseButton {
    display: block;
}

.SingleFileIcon.loading:hover .CircularCloseButton {
    display: none;
}

.SingleFileIcon .FileThumbnail {
    z-index: 1;
}

.SingleFileIcon .SingleFileIcon-metadata {
    display: none;
    position: absolute;
    left: 50px;
    top: 0px;
    bottom: 0px;
    z-index: 2;
    flex-direction: column;
    justify-content: center;
}

.SingleFileIcon:hover .SingleFileIcon-metadata {
    display: flex;
}

.SingleFileIcon .SingleFileIcon-metadata .SingleFileIcon-metadata-container {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 5px;
    max-height: 35px;
    overflow: hidden;
    /* text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    line-clamp: 2; */
    font-size: 14px;
}