.ErrorAlert {
    position: relative;
}

.ErrorAlert .ErrorAlert-close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: transparent;
    padding-top: 8px;
    padding-right: 12px;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
}

.ErrorAlert .ErrorAlert-container {
    background-color: #ff9d9e;
    border-color: #ff0000;
    border-width: 2px;
    border-style: solid;
    color: #fff;
    padding-top: 20px;
    padding-right: 40px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-radius: 5px;
}