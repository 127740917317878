.CheckboxOption .CheckboxOption-container {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.CheckboxOption .CheckboxOption-container .CheckboxOption-box {
    border-style: solid;
    border-width: 2px;
    border-color: #000;
    border-radius: 2.5px;
    background-color: transparent;
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.CheckboxOption.error .CheckboxOption-container .CheckboxOption-box {
    border-color: #E06849;
}

.CheckboxOption.selected .CheckboxOption-container .CheckboxOption-box {
    background-color: #4AC1E0;
}

.CheckboxOption .CheckboxOption-container .CheckboxOption-text {
    flex: 1;
}

.CheckboxOption.error .CheckboxOption-container .CheckboxOption-text {
    color: #E06849;
}