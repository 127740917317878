.AddressField .AddressField-container {
    position: relative;
}

.AddressField .AddressField-container input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-progress-appearance: none;
    border: none;
    width: 100%;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    font-family: 'Int Circular 2 TT', sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #000;
}

.FormField.error .AddressField .AddressField-container input {
    border-bottom-color: #E06849;
}

.AddressField .AddressField-container input::placeholder {
    font-family: 'Int Circular 2 TT', sans-serif;
    font-size: 16px;
    color: #8f8f8f;
}

.AddressField .AddressField-container input:focus {
    outline: none;
}

.AddressField .AddressField-container .AddressField-default {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #525252;
    overflow-x: hidden;
}

.AddressField .AddressField-container .AddressField-default .AddressField-default-value {
    color: #525252;
    font-size: 16px;
}

.AddressField .AddressField-container .AddressField-default .AddressField-change-button {
    font-size: 12px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #000;
    font-weight: bold;
}

.AddressField .AddressField-container .AddressField-predictions-container {
    display: none;
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0px;
    border-style: solid;
    border-width: 2px;
    border-color: #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fff;
    max-height: 350px;
    overflow-x: clip;
    overflow-y: scroll;
    z-index: 2;
}

.AddressField .AddressField-container .AddressField-predictions-container.showing {
    display: block;
}

.AddressField .AddressField-container .AddressField-predictions-container .AddressField-prediction-container {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    cursor: pointer;
}

.AddressField .AddressField-container .AddressField-predictions-container .AddressField-prediction-container:active {
    background-color: #000;
    color: #fff;
}