.FormField {
    margin-bottom: 40px;
}

.FormField .FormField-error-container {
    display: none;
    padding-top: 10px;
    padding-left: 5px;
}

.FormField.error .FormField-error-container {
    display: block;
}

.FormField .FormField-error-container .FormField-error-message {
    color: #E06849;
    font-size: 12px;
}