.CollapsibleNotes.hide {
    display: none;
}

.CollapsibleNotes .CollapsibleNotes-bar {
    background-color: #000;
    color: #FFF;
    border-top-left-radius: 2.5px;
    border-top-right-radius: 2.5px;
    padding-top: 7.5px;
    padding-right: 10px;
    padding-bottom: 7.5px;
    padding-left: 10px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
}

.CollapsibleNotes .CollapsibleNotes-bar .CollapsibleNotes-bar-wrapper {
    display: table;
}

.CollapsibleNotes .CollapsibleNotes-bar .CollapsibleNotes-bar-wrapper .CollapsibleNotes-bar-container {
    display: table-row;
}

.CollapsibleNotes .CollapsibleNotes-bar .CollapsibleNotes-bar-wrapper .CollapsibleNotes-bar-text {
    display: table-cell;
    align-items: center;
}

.CollapsibleNotes .CollapsibleNotes-bar .CollapsibleNotes-bar-wrapper .CollapsibleNotes-bar-container .CollapsibleNotes-bar-icon {
    font-size: 16px;
    padding-right: 10px;
}

.CollapsibleNotes .CollapsibleNotes-bar .CollapsibleNotes-bar-wrapper .CollapsibleNotes-bar-container .CollapsibleNotes-bar-instruction {
    padding-right: 3px;
}

.CollapsibleNotes.closed .CollapsibleNotes-bar {
    border-bottom-left-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
}

.CollapsibleNotes .CollapsibleNotes-content {
    border-width: 2px;
    border-style: solid;
    border-color: #000;
    padding: 10px;
    color: #4F4E4D;
}

.CollapsibleNotes.closed .CollapsibleNotes-content {
    display: none;
}