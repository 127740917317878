.TableHeader .TableHeader-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    background-color: #000;
    color: #fff;
}