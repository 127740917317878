.SelectionField .SelectionField-container {
    position: relative;
}

.SelectionField .SelectionField-value-container {
    position: relative;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #000;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    z-index: 1;
}

.FormField.error .SelectionField .SelectionField-value-container {
    border-bottom-color: #E06849;
}

.SelectionField.selected .SelectionField-placeholder {
    display: none;
}

.SelectionField .SelectionField-placeholder {
    color: #8f8f8f;
}

.SelectionField .SelectionField-value {
    display: none;
}

.SelectionField.selected .SelectionField-value {
    display: block;
}

.SelectionField .SelectionField-options {
    display: none;
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0px;
    border-style: solid;
    border-width: 2px;
    border-color: #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-height: 350px;
    overflow-x: clip;
    overflow-y: scroll;
    z-index: 2;
    background-color: #fff;
}

.SelectionField .SelectionField-options::-webkit-scrollbar {
    width: 0.75em;
}

.SelectionField .SelectionField-options::-webkit-scrollbar-track {
    background-color: #efefef;
    border-bottom-right-radius: 10px;
}

.SelectionField .SelectionField-options::-webkit-scrollbar-thumb {
    background-color: #1F1E1D;
    border-radius: 10px;
}

.SelectionField .SelectionField-options .SelectionOption:last-child .SelectionOption-container {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.SelectionField.open .SelectionField-options {
    display: block;
}