.BranchSelector {
    margin-bottom: 10px;
}

.BranchSelector .BranchSelector-container .Branch {
    margin-right: 10px;
}

.BranchSelector .BranchSelector-container .Branch .Branch-container {
    background-color: #eaeaea;
    padding-top: 2px;
    padding-right: 7px;
    padding-bottom: 2px;
    padding-left: 7px;
    cursor: pointer;
    border-radius: 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.BranchSelector .BranchSelector-container .Branch.selected .Branch-container {
    background-color: #000;
    color: #fff;
    cursor: default;
}