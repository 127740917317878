.HomeScreen {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.HomeScreen-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 750px;
    text-align: center;
    box-sizing: border-box;
}

.HomeScreen-logo {
    width: 250px;
    margin-bottom: 35px;
}

.HomeScreen-logo img {
    max-width: 100%;
}

.HomeScreen-button {
    width: 100%;
    max-width: 550px;
    margin-bottom: 20px;
}

.HomeScreen-button button {
    width: 100%;
}

.HomeScreen-message {
    width: 100%;
    max-width: 550px;
    font-size: 14px;
}