.MultipleFileUploader .MultipleFileUploader-input {
    display: none;
}

.MultipleFileUploader .MultipleFileUploader-icons {
    display: flex;
    flex-direction: row;
}

.MultipleFileUploader .MultipleFileUploader-icons .MultipleFileUploader-files {
    display: flex;
    flex-direction: row-reverse;
}

.MultipleFileUploader .MultipleFileUploader-icons .SingleFileIcon {
    margin-right: 7.5px;
}