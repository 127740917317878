.CircularCloseButton {
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.65);
    color: #fff;
    text-align: center;
}