.ProfileField {
    margin-bottom: 10px;
}

.ProfileField .ProfileField-container {
    text-overflow: ellipsis;
}

.ProfileField .ProfileField-container .ProfileField-label {
    font-weight: 800;
    margin-bottom: 5px;
}

.ProfileField .ProfileField-container .ProfileField-value {
    margin-left: 15px;
}