.ProfileHeader {
    margin-bottom: 30px;
}

.ProfileHeader .ProfileHeader-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.ProfileHeader .ProfileHeader-container .ProfileHeader-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000;
    color: #fff;
    padding: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.ProfileHeader .ProfileHeader-container .ProfileHeader-title .ProfileHeader-title-text {
    font-size: 32px;
    font-weight: bold;
}

.ProfileHeader .ProfileHeader-container .ProfileHeader-footer .ProfileHeader-footer-container {
    padding: 15px;
    border-style: solid;
    border-width: 2px;
    border-color: #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 16px;
    font-weight: bold;
}