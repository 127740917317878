.MultiLineTextField .MultiLineTextField-container textarea {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-progress-appearance: none;
    border: none;
    width: 100%;
    height: 100px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    font-family: 'Int Circular 2 TT', sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #000;
    resize: none;
}

.FormField.error .MultiLineTextField .MultiLineTextField-container textarea {
    border-bottom-color: #E06849;
}

.MultiLineTextField .MultiLineTextField-container textarea::placeholder {
    font-family: 'Int Circular 2 TT', sans-serif;
    font-size: 16px;
    color: #8f8f8f;
}

.MultiLineTextField .MultiLineTextField-container textarea:focus {
    outline: none;
}