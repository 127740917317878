.ProfileMetadata .ProfileMetadata-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
}

.ProfileMetadata.collapse .ProfileMetadata-container {
    margin-bottom: 30px;
}

.ProfileMetadata .ProfileMetadata-container .ProfileMetadata-collapse-menu {
    margin-bottom: 30px;
    cursor: pointer;
}

.ProfileMetadata.collapse .ProfileMetadata-container .ProfileMetadata-collapse-menu {
    margin-bottom: 0px;
}

.ProfileMetadata .ProfileMetadata-container .ProfileMetadata-collapse-menu .ProfileMetadata-collapse-menu-container {
    background-color: #000;
    color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ProfileMetadata .ProfileMetadata-container .ProfileMetadata-collapse-menu .ProfileMetadata-collapse-menu-container .ProfileMetadata-collapse-menu-title {
    font-size: 18px;
}

.ProfileMetadata .ProfileMetadata-container .ProfileMetadata-collapse-menu .ProfileMetadata-collapse-menu-container .ProfileMetadata-collapse-menu-icon {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
}

.ProfileMetadata .ProfileMetadata-container .ProfileMetadata-collapse-menu .ProfileMetadata-collapse-menu-container .ProfileMetadata-collapse-menu-icon img {
    max-width: 100%;
    /* transform: rotate(180deg); */
}

.ProfileMetadata.collapse .ProfileMetadata-container .ProfileMetadata-collapse-menu .ProfileMetadata-collapse-menu-container .ProfileMetadata-collapse-menu-icon img {
    /* transform: rotate(0deg); */
}

.ProfileMetadata .ProfileMetadata-container .ProfileMetadata-contents {
    display: flex;
    flex-direction: column;
}

.ProfileMetadata.collapse .ProfileMetadata-container .ProfileMetadata-contents {
    display: none;
}

@media screen and (min-width: 992px) {
    .ProfileMetadata .ProfileMetadata-container {
        width: 300px;
        margin-bottom: 0px;
        margin-right: 60px;
    }

    .ProfileMetadata .ProfileMetadata-container .ProfileMetadata-contents .ProfileHeader {
        margin-bottom: 30px;
    }
}