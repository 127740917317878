.CalendarSelection {
    display: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.CalendarSelection.open {
    display: block;
}

.CalendarSelection .CalendarSelection-container {
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    border-color: #000;
    overflow-x: clip;
    overflow-y: clip;
}

.CalendarSelection-navigator {
    padding: 5px;
    background-color: #eaeaea;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #000;
}

.CalendarSelection-navigator .CalendarSelection-month-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CalendarSelection .CalendarSelection-month-selection .CalendarSelection-previous-month, .CalendarSelection .CalendarSelection-month-selection .CalendarSelection-next-month {
    cursor: pointer;
}

.CalendarSelection-days {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #fff;
}

.CalendarSelection-days .CalendarSelection-week {
    display: flex;
    flex-direction: row;
}

.CalendarSelection-days .CalendarSelection-week .CalendarSelection-day {
    flex: 1;
    text-align: center;
    padding: 2px;
    cursor: pointer;
}

.CalendarSelection-days .CalendarSelection-week .CalendarSelection-day.dull {
    opacity: 0.5;
}

.CalendarSelection-days .CalendarSelection-week .CalendarSelection-day.selected {
    color: #4AC1E0;
}

.CalendarSelection-days .CalendarSelection-week .CalendarSelection-day.dull.selected {
    opacity: 1;
}