.NumericField .NumericField-container input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-progress-appearance: none;
    border: none;
    width: 100%;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    font-family: 'Int Circular 2 TT', sans-serif;
    font-size: 16px;
    box-sizing: border-box;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #000;
}

.NumericField.invalid .NumericField-container input {
    border-bottom-color: #E06849;
    color: #E06849;
}

.NumericField .NumericField-container input::placeholder {
    font-family: 'Int Circular 2 TT', sans-serif;
    font-size: 16px;
    color: #8f8f8f;
}

.NumericField .NumericField-container input:focus {
    outline: none;
}

.NumericField .NumericField-container .NumericField-error-message {
    display: none;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 0px;
    color: #E06849;
    font-family: 'Int Circular 2 TT Book', sans-serif;
    font-weight: 500;
    font-size: 12px;
}

.NumericField.invalid .NumericField-container .NumericField-error-message {
    display: block;
}