.NewLeadScreen-form, .NewLeadScreen-header, .NewLeadScreen-description {
    max-width: 750px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.NewLeadScreen-header {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
}

.NewLeadScreen-description {
    margin-bottom: 25px;
}

.NewLeadScreen-form {
    background-color: #eaeaea;
    border-width: 2px;
    border-style: solid;
    border-color: #676767;
    padding: 20px;
}

.NewLeadScreen-calendar-auth {
    margin-bottom: 40px;
}