.NestedFieldGroup {
    border-style: solid;
    border-width: 1px;
    border-color: #676767;
    background-color: #eaeaea;
    margin-bottom: 40px;
}

.NestedFieldGroup .NestedFieldGroup-container {
    padding: 20px;
}

.NestedFieldGroup .NestedFieldGroup-title {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    background-color: #676767;
    color: #fff;
    padding: 10px;
}

.NestedFieldGroup .NestedFieldGroup-container .FormField {
    margin-bottom: 20px;
}

.NestedFieldGroup .NestedFieldGroup-container .FormField:last-child {
    margin-bottom: 0px;
}