.SelectionOption .SelectionOption-container {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    background-color: #fff;
    cursor: pointer;
}

.SelectionOption .SelectionOption-container:active {
    background-color: #000;
}

.SelectionOption .SelectionOption-container .SelectionOption-text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.SelectionOption .SelectionOption-container:hover .SelectionOption-text {
    opacity: 0.5;
}

.SelectionOption .SelectionOption-container:active .SelectionOption-text {
    opacity: 1;
    color: #fff;
}
