.Table .Table-container .Table-rows {
    border-style: solid;
    border-width: 2px;
    border-color: #1F1E1D;
    border-radius: 10px;
    overflow-x: clip;
    overflow-y: scroll;
}

.Table .Table-container .Table-rows .TableRow:last-child .TableRow-container {
    border-bottom: none;
}