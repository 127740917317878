.DateTimeField .DateTimeField-container {
    display: flex;
    flex-direction: column;
}

.DateTimeField .DateTimeField-container .DateField {
    flex: 1;
}

.DateTimeField .DateTimeField-container .DateTimeField-time {
    display: flex;
    flex-direction: row;
}

.DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-hour, .DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-minute {
    margin-right: 10px;
    min-width: 75px;
}

.DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-hour input, .DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-minute input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-progress-appearance: none;
    width: 100%;
    box-sizing: border-box;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #000;
    font-size: 16px;
    font-family: 'Int Circular 2 TT', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.FormField.error .DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-hour input, .FormField.error .DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-minute input {
    border-bottom-color: #E06849;
}

.DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-hour input:focus, .DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-minute input:focus {
    outline: none;
}

.DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-period {
    position: relative;
    min-width: 75px;
}

.DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-period .DateTimeField-period-value {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #000;
    background-color: #fff;
    cursor: pointer;
    z-index: 1;
}

.FormField.error .DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-period .DateTimeField-period-value {
    border-bottom-color: #E06849;
}

.DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-period.open .DateTimeField-period-value {
    cursor: default;
}

.DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-period .DateTimeField-period-options {
    display: none;
    position: absolute;
    z-index: 2;
    left: 0px;
    right: 0px;
    border-style: solid;
    border-width: 2px;
    border-color: #000;
    border-top-width: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-period.open .DateTimeField-period-options {
    display: block;
    background-color: #fff;
}

.DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-period .DateTimeField-period-options .DateTimeField-period-option {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    cursor: pointer;
}

@media screen and (min-width: 576px) {
    .DateTimeField .DateTimeField-container {
        flex-direction: row;
    }

    .DateTimeField .DateTimeField-container .DateField {
        margin-right: 10px;
    }

    .DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-hour, .DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-minute, .DateTimeField .DateTimeField-container .DateTimeField-time .DateTimeField-period {
        max-width: 100px;
    }
}